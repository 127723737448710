import { useState, useCallback, useEffect } from "react";
import * as S from "./style";
import Logo from "../Logo";
import NavigationItem from "../NavigationItem";
import { HeaderItems } from "./HeaderItems";
import { useLocation } from "react-router-dom";
import XIcon from "../XIcon";

export default function Header({ open, toggleMobileMenu }) {
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const pathname = useLocation()?.pathname;

  const hasBg = pathname.match(
    /^\/(blog|jobs|careers)\/\d+$|^\/terms-of-service|eula|privacy|press$/
  );

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 900);
  }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 100 && !isMobile) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleScroll, handleResize]);

  const children = (
    <>
      <Logo />
      <S.NavigationContainer $open={open}>
        {open && (
          <NavigationItem
            text="HOME"
            href="/"
            active={pathname === "/"}
            onClick={toggleMobileMenu}
          />
        )}
        {HeaderItems.map((item) => (
          <NavigationItem
            key={item.text}
            text={item.text}
            href={item.href}
            active={
              pathname === item.href ||
              (item.href === "/blog" && pathname.match(/^\/blog\/\d+$/)) ||
              (item.href === "/careers" && pathname.match(/^\/careers\/\d+$/))
            }
            onClick={open ? toggleMobileMenu : null}
          />
        ))}
      </S.NavigationContainer>
      {open && <XIcon onClick={toggleMobileMenu} />}
    </>
  );

  return open ? (
    <S.MobileHeader>
      <S.StyledSvg
        width="375"
        height="813"
        viewBox="0 0 375 813"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M943.838 83.1298C864.351 0.662979 764.391 -63.7735 646.666 -109.293C543.695 -148.901 439.519 -169 337.451 -169C325.106 -169 312.461 -169 300.117 -168.113C185.704 -162.793 76.7108 -136.486 -24.153 -89.4889C-124.113 -43.0829 -214.439 20.4669 -292.421 99.9779C-369.8 179.193 -430.017 272.006 -471.566 376.345C-512.213 478.616 -532.987 585.321 -532.987 693.798C-533.589 802.572 -513.417 910.163 -473.372 1013.32C-433.027 1116.48 -372.509 1210.18 -294.227 1291.76C-215.342 1373.93 -116.285 1438.36 0.837463 1483.29C103.809 1522.9 207.081 1543 307.945 1543C321.494 1543 335.043 1542.7 348.591 1541.82C463.004 1535.32 571.997 1508.42 673.162 1462.01C774.327 1415.9 864.653 1352.06 941.43 1272.54C1017.91 1193.33 1077.82 1100.22 1119.37 995.881C1158.51 897.453 1178.69 792.227 1178.99 682.862C1179.59 574.089 1160.02 466.202 1120.88 362.749C1081.43 259.296 1021.82 165.301 943.236 83.1298H943.838ZM640.645 1005.04C496.726 1143.08 311.558 1225.25 145.058 1225.25C33.6559 1225.25 -61.186 1189.49 -128.93 1121.5C-213.234 1036.67 -246.053 911.937 -220.762 770.354C-195.471 627.589 -114.478 485.119 6.55853 368.66C150.176 230.624 335.344 148.453 501.844 148.453C613.547 148.453 708.088 184.514 775.832 252.497C950.763 428.072 889.944 765.625 640.344 1005.04H640.645Z"
          fill="#E545CB"
        />
      </S.StyledSvg>
      {children}
    </S.MobileHeader>
  ) : (
    <S.HeaderContainer $scrolled={scrolled} $hasBg={hasBg}>
      <S.Header $open={open}>{children}</S.Header>
    </S.HeaderContainer>
  );
}
