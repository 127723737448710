import React, { useState } from "react";
import * as S from "./style";
import { contentData } from "./ContentData";
import MobileContainer from "./MobileContainer";

export default function ContentSection() {
  const [firstActive, setFirstActive] = useState(false);
  const [secondActive, setSecondActive] = useState(false);

  const toggleActive = (index) => {
    if (index === 2) {
      setFirstActive((prev) => !prev);
    } else if (index === 9) {
      setSecondActive((prev) => !prev);
    }
  };

  return (
    <S.Container $xl>
      <S.Title>Privacy Policy</S.Title>
      <S.LasUpdatedText>Last updated: 18th September 2024</S.LasUpdatedText>
      <S.ContentSectionContainer>
        <S.NavigationList>
          {contentData.map((item, index) => {
            const isCategory2 = index === 2;
            const isCategory9 = index === 9;

            const shouldRenderSubCategories =
              (isCategory2 && firstActive) || (isCategory9 && secondActive);

            const subCategorySlice = isCategory2
              ? contentData.slice(3, 9)
              : isCategory9
              ? contentData.slice(10, 15)
              : [];

            if (isCategory2 || isCategory9) {
              return (
                <React.Fragment key={item.id}>
                  <S.NavigationItem>
                    <S.BulletPoint>•</S.BulletPoint>
                    <S.NavigationTitle
                      href={`#${item.id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        const target = document.getElementById(item.id);
                        if (target) {
                          target.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }
                      }}
                    >
                      {item.title}
                    </S.NavigationTitle>
                    {(isCategory2 && firstActive) ||
                    (isCategory9 && secondActive) ? (
                      <S.ChevronContainer
                        onClick={() => toggleActive(index)}
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_1201_1334"
                          // style="mask-type:alpha"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="32"
                          height="32"
                        >
                          <path
                            d="M27.0613 21.0613C26.9219 21.2011 26.7563 21.3121 26.574 21.3878C26.3917 21.4635 26.1962 21.5025 25.9988 21.5025C25.8013 21.5025 25.6059 21.4635 25.4235 21.3878C25.2412 21.3121 25.0756 21.2011 24.9363 21.0613L16 12.1251L7.06126 21.0613C6.77946 21.3431 6.39727 21.5014 5.99876 21.5014C5.60024 21.5014 5.21805 21.3431 4.93626 21.0613C4.65446 20.7795 4.49615 20.3973 4.49615 19.9988C4.49615 19.6003 4.65446 19.2181 4.93626 18.9363L14.9363 8.9363C15.0756 8.79646 15.2412 8.6855 15.4235 8.6098C15.6059 8.53409 15.8013 8.49512 15.9988 8.49512C16.1962 8.49512 16.3917 8.53409 16.574 8.6098C16.7563 8.6855 16.9219 8.79646 17.0613 8.9363L27.0613 18.9363C27.2011 19.0757 27.3121 19.2412 27.3878 19.4236C27.4635 19.6059 27.5024 19.8014 27.5024 19.9988C27.5024 20.1962 27.4635 20.3917 27.3878 20.574C27.3121 20.7564 27.2011 20.9219 27.0613 21.0613Z"
                            fill="#FCFAFF"
                          />
                        </mask>
                        <g mask="url(#mask0_1201_1334)">
                          <path
                            d="M32 0H11.5949C10.8608 1.70074 10.4539 3.57593 10.4539 5.54613C10.4539 13.2809 16.7241 19.5511 24.4589 19.5511C27.2354 19.5511 29.8232 18.7432 32 17.3495V0Z"
                            fill="#1C122E"
                          />
                        </g>
                        <mask
                          id="mask1_1201_1334"
                          // style="mask-type:alpha"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="32"
                          height="32"
                        >
                          <path
                            d="M27.0613 21.0613C26.9219 21.2011 26.7563 21.3121 26.574 21.3878C26.3917 21.4635 26.1962 21.5025 25.9988 21.5025C25.8013 21.5025 25.6059 21.4635 25.4235 21.3878C25.2412 21.3121 25.0756 21.2011 24.9363 21.0613L16 12.1251L7.06126 21.0613C6.77946 21.3431 6.39727 21.5014 5.99876 21.5014C5.60024 21.5014 5.21805 21.3431 4.93626 21.0613C4.65446 20.7795 4.49615 20.3973 4.49615 19.9988C4.49615 19.6003 4.65446 19.2181 4.93626 18.9363L14.9363 8.9363C15.0756 8.79646 15.2412 8.6855 15.4235 8.6098C15.6059 8.53409 15.8013 8.49512 15.9988 8.49512C16.1962 8.49512 16.3917 8.53409 16.574 8.6098C16.7563 8.6855 16.9219 8.79646 17.0613 8.9363L27.0613 18.9363C27.2011 19.0757 27.3121 19.2412 27.3878 19.4236C27.4635 19.6059 27.5024 19.8014 27.5024 19.9988C27.5024 20.1962 27.4635 20.3917 27.3878 20.574C27.3121 20.7564 27.2011 20.9219 27.0613 21.0613Z"
                            fill="#FCFAFF"
                          />
                        </mask>
                        <g mask="url(#mask1_1201_1334)">
                          <path
                            d="M32 17.3495C29.8232 18.7432 27.2354 19.5511 24.4589 19.5511C16.7241 19.5511 10.4539 13.2809 10.4539 5.54613C10.4539 3.57593 10.8607 1.70074 11.5949 0L0 0V32H32V17.3495Z"
                            fill="#1C122E"
                          />
                        </g>
                      </S.ChevronContainer>
                    ) : (
                      <S.ChevronContainer
                        onClick={() => toggleActive(index)}
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_1439_1600"
                          // style="mask-type:alpha"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="32"
                          height="32"
                        >
                          <path
                            d="M27.0613 13.0615L17.0613 23.0615C16.9219 23.2013 16.7563 23.3123 16.574 23.388C16.3917 23.4637 16.1962 23.5027 15.9988 23.5027C15.8013 23.5027 15.6059 23.4637 15.4235 23.388C15.2412 23.3123 15.0756 23.2013 14.9363 23.0615L4.93626 13.0615C4.65446 12.7797 4.49615 12.3975 4.49615 11.999C4.49615 11.6005 4.65446 11.2183 4.93626 10.9365C5.21805 10.6547 5.60024 10.4964 5.99876 10.4964C6.39727 10.4964 6.77946 10.6547 7.06126 10.9365L16 19.8752L24.9388 10.9352C25.2205 10.6534 25.6027 10.4951 26.0013 10.4951C26.3998 10.4951 26.782 10.6534 27.0638 10.9352C27.3455 11.217 27.5039 11.5992 27.5039 11.9977C27.5039 12.3962 27.3455 12.7784 27.0638 13.0602L27.0613 13.0615Z"
                            fill="#FCFAFF"
                          />
                        </mask>
                        <g mask="url(#mask0_1439_1600)">
                          <path
                            d="M32 0H11.5949C10.8608 1.70074 10.4539 3.57593 10.4539 5.54613C10.4539 13.2809 16.7241 19.5511 24.4589 19.5511C27.2354 19.5511 29.8232 18.7432 32 17.3495V0Z"
                            fill="#1C122E"
                          />
                        </g>
                        <mask
                          id="mask1_1439_1600"
                          // style="mask-type:alpha"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="32"
                          height="32"
                        >
                          <path
                            d="M27.0613 13.0615L17.0613 23.0615C16.9219 23.2013 16.7563 23.3123 16.574 23.388C16.3917 23.4637 16.1962 23.5027 15.9988 23.5027C15.8013 23.5027 15.6059 23.4637 15.4235 23.388C15.2412 23.3123 15.0756 23.2013 14.9363 23.0615L4.93626 13.0615C4.65446 12.7797 4.49615 12.3975 4.49615 11.999C4.49615 11.6005 4.65446 11.2183 4.93626 10.9365C5.21805 10.6547 5.60024 10.4964 5.99876 10.4964C6.39727 10.4964 6.77946 10.6547 7.06126 10.9365L16 19.8752L24.9388 10.9352C25.2205 10.6534 25.6027 10.4951 26.0013 10.4951C26.3998 10.4951 26.782 10.6534 27.0638 10.9352C27.3455 11.217 27.5039 11.5992 27.5039 11.9977C27.5039 12.3962 27.3455 12.7784 27.0638 13.0602L27.0613 13.0615Z"
                            fill="#FCFAFF"
                          />
                        </mask>
                        <g mask="url(#mask1_1439_1600)">
                          <path
                            d="M32 17.3495C29.8232 18.7432 27.2354 19.5511 24.4589 19.5511C16.7241 19.5511 10.4539 13.2809 10.4539 5.54613C10.4539 3.57593 10.8607 1.70074 11.5949 0L0 0V32H32V17.3495Z"
                            fill="#1C122E"
                          />
                        </g>
                      </S.ChevronContainer>
                    )}
                  </S.NavigationItem>

                  {shouldRenderSubCategories &&
                    subCategorySlice.map((subItem) => (
                      <S.NavigationItem key={subItem.id} $isSubCategory={true}>
                        <S.BulletPoint>•</S.BulletPoint>
                        <S.NavigationTitle
                          href={`#${subItem.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                            const target = document.getElementById(subItem.id);
                            if (target) {
                              target.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                              });
                            }
                          }}
                        >
                          {subItem.title}
                        </S.NavigationTitle>
                      </S.NavigationItem>
                    ))}
                </React.Fragment>
              );
            }

            if (!item.isSubCategory) {
              return (
                <S.NavigationItem key={item.id}>
                  <S.BulletPoint>•</S.BulletPoint>
                  <S.NavigationTitle
                    href={`#${item.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      const target = document.getElementById(item.id);
                      if (target) {
                        target.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }
                    }}
                  >
                    {item.title}
                  </S.NavigationTitle>
                </S.NavigationItem>
              );
            }

            return null;
          })}
        </S.NavigationList>
        <S.DescriptionContainer>
          {contentData.map((item) => (
            <S.DescriptionItem
              key={item.id}
              $isSubCategory={item.isSubCategory}
            >
              <S.DescriptionTitle
                id={item.id}
                $isSubCategory={item.isSubCategory}
              >
                {item.title}
              </S.DescriptionTitle>
              {item.isSubCategory && (
                <S.DescriptionSubTitle id={item.id} $isGrid={item.isGrid}>
                  {item.title}
                </S.DescriptionSubTitle>
              )}
              {item.content}
            </S.DescriptionItem>
          ))}
        </S.DescriptionContainer>
      </S.ContentSectionContainer>
      <MobileContainer />
    </S.Container>
  );
}
