import * as S from "./style";

export const contentData = [
  {
    id: "acceptance",
    title: "Acceptance",
    content: (
      <>
        <S.DescriptionText>
          This end user license agreement (the “<strong>EULA</strong>”),
          together with Modoyo’s Terms of Service (as defined below), creates a
          legally binding agreement between Modoyo AB, or its relevant
          affiliate, (“
          <strong>Modoyo</strong>”, “<strong>we</strong>” or “
          <strong>us</strong>”) and you (“<strong>you</strong>” or a “
          <strong>user</strong>”).
        </S.DescriptionText>
        <S.DescriptionText>
          The EULA, together with any other documents expressly incorporated by
          reference, govern the access to and use of Modoyo’s website(s)
          (including{" "}
          <a href="https://www.modoyo.com" target="_blank" rel="noreferrer">
            www.modoyo.com
          </a>
          ) games and other applications, including, but not limited to any
          content, functionality, features, instructions, documentation and
          services offered in connection therewith, whether installed or used on
          a computer, console or mobile device (collectively the “
          <strong>Services</strong>”), whether to guests, visitors or registered
          players and users.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "terms-of-service",
    title: "Terms of Service and The Agreement",
    content: (
      <>
        <S.DescriptionText>
          Your access to and use of the Services are also governed by Modoyo’s
          Terms of Service, available at{" "}
          <a
            href="https://modoyo.com/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            www.modoyo.com/terms-of-service
          </a>{" "}
          (the “<strong>Terms of Service</strong>”). The Terms of Service and
          this EULA constitute one, integrated agreement (hereinafter
          collectively referred to as the “<strong>Agreement</strong>”). (In the
          event of any inconsistency between the Terms of Service and this EULA,
          this EULA shall however take precedence).
        </S.DescriptionText>
        <S.DescriptionText>
          Provisions on payment terms, warranty disclaimers, limitations of
          liability, applicable law and jurisdiction for disputes, amongst
          others, are set out in the Terms of Service.
        </S.DescriptionText>
        <S.DescriptionText>
          The Terms of Service are hereby incorporated by reference and the
          Agreement represents the complete agreement and understanding between
          you and Modoyo relating to the access to and use of the Services.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "limited-license-grant",
    title: "Limited License Grant",
    content: (
      <>
        <S.DescriptionText>
          Subject to, and expressly conditioned upon your compliance with, the
          terms and conditions of this Agreement, including but not limited to
          your adherence to the License Restrictions set out in Section 4 and
          the Code of Conduct set out in Section 6, we hereby grant you a
          non-exclusive, non-assignable, non-transferable, non-sublicensable,
          revocable license to install, access and use for the intended purpose
          the particular Service(s) for which you have purchased a license from
          us or our authorized representative (e.g., Steam). This license is
          granted solely for personal and non-commercial entertainment purposes
          and is limited to downloading and installing the necessary software on
          a single, personal device.
        </S.DescriptionText>
        <S.DescriptionText>
          Your access to and use of the Service(s) is licensed to you, not sold.
          You hereby acknowledge that no rights, title or interest of ownership
          and/or intellectual property rights in connection with or arising out
          of the Service(s) is being transferred or assigned to you, and that
          this Agreement should not be construed as a sale, transfer or
          assignment of any of Modoyo’s, or our licensors’ , rights, titles or
          interests.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "license-restrictions",
    title: "License Restrictions",
    content: (
      <>
        <S.DescriptionText>
          You agree that you will not, in whole or in part, directly or
          indirectly, under any circumstances, do or assist others in doing the
          following except when expressly authorized by Modoyo in writing:
        </S.DescriptionText>
        <S.DefinitionsList>
          <S.DefinitionsListItem>
            copy, reproduce translate, reverse engineer, derive source code
            from, modify, disassemble, decompile, translate, change or create
            derivative works based on or related to, any of the Services;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            create, use, offer, promote, advertise, make available and/or
            distribute:
            <S.DefinitionsSubList>
              <S.DefinitionsListItem>
                cheats including any methods (whether via hardware, software or
                otherwise) that exploit in-game bugs or provide an unfair
                advantage over other players,
              </S.DefinitionsListItem>
              <S.DefinitionsListItem>
                bots, including any code or software enabling automated control
                of gameplay or game features, such as character actions,
              </S.DefinitionsListItem>
              <S.DefinitionsListItem>
                hacks, including unauthorized access to or modification of any
                part of the Service’s software, or
              </S.DefinitionsListItem>
              <S.DefinitionsListItem>
                any code or software that alters or facilitates gameplay or
                modifies any functionality of the Services or its components;
              </S.DefinitionsListItem>
            </S.DefinitionsSubList>
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            modify any file or any other part of the Services that Modoyo does
            not specifically authorize you to modify;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            use the Services, including a game, in connection with any
            unauthorized third-party “cloud computing” services, “cloud gaming”
            services, or any software or service designed to enable the
            unauthorized streaming or transmission of game content from a
            third-party server to any device;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            use any unauthorized process or software that intercepts, collects,
            reads, or “mines” information generated or stored by the Services;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            create, utilize or transact in any in-game item created or copied by
            exploiting a design flaw, undocumented problem, or program bug in
            the Services;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            host, provide or develop matchmaking services for the Services, or
            intercept, emulate or redirect the communication protocols used by
            Modoyo in any way, for any purpose, including without limitation
            unauthorized play over the internet, network play, or as part of
            content aggregation networks;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            facilitate, create or maintain any unauthorized connection to the
            Services including without limitation (i) any connection to any
            unauthorized server that emulates, or attempts to emulate, the
            Services, or (ii) any connection using third-party programs or tools
            not expressly authorized by Modoyo;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            attempt to sell, sublicense, rent, lease, grant a security interest
            in or otherwise transfer any copy of the Services or any component
            thereof, or any of your license or other rights granted hereunder,
            to any other party in any way not expressly authorized herein; or
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            disrupting any computer, system, infrastructure or software used to
            support, enable or otherwise monitor the Services or any part
            thereof.
          </S.DefinitionsListItem>
        </S.DefinitionsList>
        <S.DescriptionText>
          You acknowledge and accept that the unauthorized use of the Services
          or other intellectual property rights owned or controlled by Modoyo or
          its licensors may be a criminal offense as well as a violation of this
          EULA. Modoyo reserves the right to take legal action in the event of
          such unauthorized use of its intellectual property rights.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "text-and-voice",
    title: "Text and Voice Chat, as well as other communications",
    content: (
      <S.DescriptionText>
        You acknowledged that some of the Services may include social features
        such as voice and text chat, or otherwise allow you to distribute, share
        or disseminate content (“<strong>Content</strong>”). When using these
        features, you are required to comply with our Code of Conduct, as set
        forth below. If we receive a report or complaint about inappropriate
        Content, we may review your chat history without prior notice. Modoyo
        reserves the right to record, store and monitor voice and text chat, as
        well as any other interactions between players or users, for purposes we
        deem necessary. These purposes may include preventing toxic behavior,
        ensuring compliance with this Agreement, or maintaining the
        functionality of social features. Monitoring and storage will be
        conducted for a duration and in a manner determined by Modoyo, in
        accordance with applicable laws.
      </S.DescriptionText>
    ),
  },
  {
    id: "code-of-conduct",
    title: "Code of Conduct",
    content: (
      <>
        <S.DescriptionText>
          When accessing or using the Services, including in the context of
          Content, you agree to abide by the following code of conduct (the “
          <strong>Code of Conduct</strong>”). You must not, in whole or in part,
          engage in, assist, promote or encourage any of the following
          prohibited activities:
        </S.DescriptionText>
        <S.DefinitionsSubList>
          <S.DefinitionsListItem>
            use the Services to violate any applicable laws or regulations;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            engage in conduct that disrupts or diminishes the game experience
            for others. This includes but is not limited to harassment,
            griefing, spamming, abusive behavior, deliberate inactivity,
            disconnecting or any other action that disrupts the normal flow of
            gameplay;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            engage in cheating or other anticompetitive behavior, such as
            boosting, collusion or matchmaking manipulation;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            use or distribute unauthorized third-party programs, including
            add-ons, mods, hacks, trainers or cheats such as software, files or
            scripts, that facilitate or enable cheating of any kind, mines or
            otherwise collects information, or bypass, modify or hack the
            Services’ interface, environment or protections;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            exploit any part of the Services for unauthorized purposes
            (including but not limited to, sale of in-game currencies or items
            outside of the Services, advertising or facilitating commercial
            transactions);
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            use any of the Services for any e-sport or group competition
            sponsored, promoted or facilitated by any commercial or non-profit
            entity without obtaining additional authorization from Modoyo or
            obtaining Modoyo’s prior written consent;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            interfere with or disrupt the Services, servers, or related
            infrastructure and hardware, including denial-of-service attacks,
            spamming, hacking, or uploading malicious software;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            harass, threaten, bully, or demean others, including Modoyo
            employees or representatives. This includes personal attacks, hate
            speech and offensive statements regarding race, political or
            philosophical beliefs, health, sexual orientation, religion or
            culture. This applies no matter the format or medium of the
            communicated Content, including texts, voice-chats, images and
            behavior. For clarity, this applies to any specific Content as such,
            as well as to communication and behavior taken as a whole;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            create, share, or promote Content that is abusive, harassing,
            profane, threatening, hateful, vulgar, obscene, sexually explicit,
            defamatory, infringing or invasive of another’s privacy;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            publish, post, upload or distribute Content that is illegal or that
            you do not have permission to freely distribute or that may
            reasonably be considered objectionable;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            post a message for any purpose other than personal communication.
            Prohibited messages include advertising, spam, chain letters,
            pyramid schemes and other types of solicitation or commercial
            activities;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            impersonate another person, or falsely imply that you are an
            employee, affiliate or representative of Modoyo’s;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            misuse in-game support or other support functions, such as making
            false reports or submitting unreasonable or repeated requests;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            attempt to obtain passwords, account information or other private
            data from other users;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            use bots or other automated tools, software or scripts to access or
            exploit the Services, such as scraping data, creating accounts or
            copying content;
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            otherwise engage in any other activity that significantly disturbs
            the peaceful, fair and respectful gaming environment of the Services
            or part thereof; or
          </S.DefinitionsListItem>
          <S.DefinitionsListItem>
            use information about users (e.g., leaderboards) for purposes
            unrelated to the Services, including attempts to identify users in
            the real world.
          </S.DefinitionsListItem>
        </S.DefinitionsSubList>
      </>
    ),
  },
  {
    id: "monitoring",
    title: "Monitoring & Anti-Cheat",
    content: (
      <S.DescriptionText>
        Modoyo may use technologies to detect, prevent, moderate, remove,
        restrict or otherwise limit unwanted Content, violations of the Code of
        Conduct, violations of the License Restrictions, cheating in the use of
        the Services, particularly in our games, or other violations of this
        Agreement. These technologies may be developed by Modoyo or third-party
        providers. T o this end, these technologies may use kernel, admin or
        user privileges, and monitor and collect from your gameplay and device's
        RAM or other memory, processes, visuals, communications and file storage
        for the purposes of detecting violations of, and enforcing, the
        above-mentioned, including the use of unauthorized third-party programs.
        Furthermore, Modoyo may collect relevant information during
        investigations of suspected violations, including account details,
        information about unauthorized third-party programs, game files that
        were altered, and timestamps of detected cheating activity.
      </S.DescriptionText>
    ),
  },
  {
    id: "restriction-and-termination",
    title: "Restriction and Termination of your access",
    content: (
      <S.DescriptionText>
        If Modoyo determines that you have engaged in cheating, violated the
        Code of Conduct, the License Restrictions or this Agreement, Modoyo
        reserves the right to take enforcement actions with respect to a
        particular Service, a part thereof or to all Services. Modoyo’s
        enforcement actions which may include, at Modoyo’s discretion: (a)
        terminating your license(s) to the Services, (b) restricting or
        suspending your access to the Services, and/ (c) removing, altering or
        restricting your Content. These enforcement actions are without
        prejudice to, and may be pursued in addition to, any other remedies or
        actions available to Modoyo under applicable law or this Agreement.
      </S.DescriptionText>
    ),
  },
];
