import * as S from "./style";

export const contentData = [
  {
    id: "acceptance",
    title: "Acceptance",
    content: (
      <>
        <S.DescriptionText>
          These terms of service (the “<strong>Terms of Service</strong>”)
          create a legally binding agreement between Modoyo AB, or its relevant
          affiliates, (“<strong>Modoyo</strong>” , “<strong>we</strong>” or “
          <strong>us</strong>”) and you, the end user (“<strong>you</strong>” or
          a “<strong>user</strong>”).
        </S.DescriptionText>
        <S.DescriptionText>
          The Terms of Service, together with any other documents expressly
          incorporated by reference, govern the access to and use of Modoyo’s
          website(s) (including{" "}
          <a href="https://modoyo.com" target="_blank" rel="noreferrer">
            www.modoyo.com
          </a>
          ), games and other applications, including but not limited to any
          content, functionality, features and services offered in connection
          therewith, whether installed or used on a computer, console or mobile
          device (collectively the “<strong>Services</strong>”), whether to
          guests, visitors or registered players and users. In this Agreement,
          Services refers collectively to all aspects of the Services and, where
          applicable, to specific components of the Services, such as individual
          video games provided by us
        </S.DescriptionText>
        <S.DescriptionText>
          You must be, and hereby affirm that you are, an adult of the legal age
          of majority in your country or state of residence.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "access",
    title: "Access and License to the Services",
    content: (
      <>
        <S.DescriptionText>
          Your access and use of our Services is licensed to you, not sold, on
          the terms set out in the end user license agreement (the “
          <strong>EULA</strong>”), which can be found via the following link:{" "}
          <a href="https://modoyo.com/eula" target="_blank" rel="noreferrer">
            www.modoyo.com/eula
          </a>
          .
        </S.DescriptionText>
        <S.DescriptionText>
          The EULA constitutes an integrated part of this Agreement, the
          conditions of which shall apply to the EULA and vice versa. In the
          event of any inconsistency between these Terms of Service and the
          EULA, the EULA shall take precedence. These Terms of Service and the
          EULA constitute one complete agreement, and these Terms of Service and
          the EULA shall hereinafter be collectively referred to as the “
          <strong>Agreement</strong>”. The EULA is hereby incorporated by
          reference and the Agreement represents the complete agreement and
          understanding between you and Modoyo relating to the access to and use
          of the Services. All access to and use of the Services and any games
          is subject to this Agreement.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "waiwer",
    title: "Waiver of Right of Withdrawal",
    content: (
      <S.DescriptionText>
        If you are a consumer located within the EU, you may have a statutory
        right to withdraw from this Agreement within fourteen (14) days of the
        transaction. HOWEVER, TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE
        LAW, BY ACCEPTING THIS AGREEMENT , YOU EXPRESSLY CONSENT TO THE
        IMMEDIATE PROVISION OF THE SERVICES AND ACKNOWLEDGE THAT , AS A RESULT ,
        YOU WILL NOT HAVE A RIGHT OF WITHDRAWAL. CONSEQUENTLY , YOUR RIGHT OF
        WITHDRAWAL WILL BE WAIVED IN ACCORDANCE WITH APPLICABLE LAW.
      </S.DescriptionText>
    ),
  },
  {
    id: "changes-to-services",
    title: "Changes to the Services",
    content: (
      <S.DescriptionText>
        We reserve the right to change, modify, suspend, discontinue or cease
        providing the Services, a particular Service or any part thereof or any
        site or material we provided in connection with the Services, in our
        sole discretion and at any time. If practicable, we will provide you
        with a thirty (30) days’ notice via the Services or via email. We will
        not be liable if for any reason all or any part of the Service is
        unavailable at any time or for any period. From time to time, we may
        restrict access to the Services or parts thereof, also in our sole
        discretion.
      </S.DescriptionText>
    ),
  },
  {
    id: "user-accounts",
    title: "User Accounts",
    content: (
      <>
        <S.DescriptionText>
          T o access and use the Services or parts thereof (including individual
          games), you may be required to create and/or maintain an account with
          Modoyo or a third-party platform (e.g., Steam).
        </S.DescriptionText>
        <S.DescriptionText>
          If you use a third-party account to access the Services, you are
          subject to the terms of use and privacy policies of that third party.
          Modoyo is not liable for any issues, obligations or disputes arising
          from your use of third-party accounts, including account suspension,
          termination or data breaches. It is your responsibility to review and
          comply with any applicable third-party terms and policies.
        </S.DescriptionText>
        <S.DescriptionText>
          You are solely responsible for maintaining the confidentiality of your
          account credentials and for all activities that occur under your
          account.
        </S.DescriptionText>
        <S.DescriptionText>
          Modoyo does not guarantee continuous or secure access to third-party
          accounts and is not responsible for any interruptions or limitations
          caused by third-party platforms. If your third-party account is
          terminated or otherwise becomes inaccessible, this may affect your
          ability to access the Services.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "in-game-purchases",
    title: "In-Game Purchases",
    content: (
      <>
        <S.DescriptionText>
          The Services may include opportunities to purchase virtual items,
          currency or game play enhancing effects such as experience boosts or
          other in-game content (collectively "<strong>Virtual Items</strong>").
        </S.DescriptionText>
        <S.DescriptionText>
          When you purchase Virtual Items, you are granted a limited, revocable,
          non-exclusive, non-transferable and non-sublicensable license to
          access and use the Virtual Items solely within the Services and as
          permitted by this Agreement. You acknowledge that you do not acquire
          any ownership interest in the Virtual Items, and all rights in and to
          the Virtual Items remain the exclusive property of Modoyo or its
          licensors.
        </S.DescriptionText>
        <S.DescriptionText>
          You acknowledge that Virtual Items have no real-world monetary value
          and cannot be redeemed for cash, goods or other items of monetary
          value outside the Services. Virtual Items are solely for entertainment
          purposes and may only be used as part of the Services and as
          authorized by Modoyo.
        </S.DescriptionText>
        <S.DescriptionText>
          Virtual Items may not be assigned, transferred, resold or exchanged,
          whether within the Services or externally, except as explicitly
          permitted by Modoyo.
        </S.DescriptionText>
        <S.DescriptionText>
          Modoyo reserves the right to manage, regulate, modify or remove
          Virtual Items at its sole discretion, and Modoyo shall have no
          liability to you or any third party if it exercises such rights.
          Virtual Items may expire or become unavailable if Modoyo discontinues
          the Services or certain features.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "fees",
    title: "Fees Payment terms",
    content: (
      <>
        <S.DescriptionText>
          We may require you to pay a fee for all or any part of the Services or
          Virtual Items. This may also change from time to time. You agree to
          timely and fully pay any such fees, including any fees for any other
          products and services ordered in connection with the Services. You are
          responsible for providing Modoyo with valid credit card information to
          secure payment. Modoyo reserves the right to cancel any order if the
          credit card information you provide is not valid. Payment may be
          processed in cooperation with third party payment service providers.
        </S.DescriptionText>
        <S.DescriptionText>
          The fees and charges are payable in accordance with payment terms in
          effect at the time the fee or the charge becomes due and payable. We
          may, from time to time, modify, amend or supplement our fees and
          fee-billing methods, effective immediately upon posting in this
          Agreement or elsewhere on our websites, apps or in the Services.
        </S.DescriptionText>
        <S.DescriptionText>
          Subject to applicable law, any applicable fees and other charges for
          fee-based Services are payable in advance and are not refundable in
          whole or in part.
        </S.DescriptionText>
        <S.DescriptionText>
          Without prejudice to any other suspension or termination rights
          hereunder, we reserve the right to suspend or terminate your account
          if you fail to timely pay any fees you owe to us, if we have to refund
          someone due to unauthorized use of a payment made from your account,
          or if you engage in any payment fraud or misrepresentation.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "personal-data",
    title: "Personal Data",
    content: (
      <>
        <S.DescriptionText>
          When you use the Services, we may collect and store data from your
          computer or device, including information about your computer or
          device, hardware, installed software and operating system (such as IP
          address and device ID), information about your usage, gameplay and
          usage statistics, system interactions and peripheral hardware. Modoyo
          uses this information to operate its business, improve its products
          and services, provide services to and communicate with you (including
          for marketing purposes), provide software updates, dynamically serve
          content and software support, enforce this Agreement and trouble-shoot
          bugs or otherwise enhance your experience. You acknowledge and agree
          to this.
        </S.DescriptionText>
        <S.DescriptionText>
          Information about how Modoyo processes personal data can be found in
          our privacy policy, located at{" "}
          <a href="https://modoyo.com/privacy" target="_blank" rel="noreferrer">
            www.modoyo.com/privacy
          </a>
          .
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "intellectual-property",
    title: "Intellectual Property Rights",
    content: (
      <>
        <S.DescriptionText>
          Modoyo and, as applicable, its licensors, remain the sole owner(s) of
          all rights, titles and interests in and to the Services. Except as
          expressly stated in this Agreement, Modoyo does not grant you any
          rights to any intellectual property rights, including without
          limitation, patents, copyrights, trade secrets and trademarks or to
          any other rights in respect to the Services. Modoyo reserves all
          rights not expressly granted under this Agreement.
        </S.DescriptionText>
        <S.DescriptionText>
          The names, logos, characters, brands and product and service names
          appearing in the Services may be protected trademarks or service marks
          of Modoyo or third parties. The use of any such trademark and/or
          service mark without the express written consent of the respective
          holder is strictly prohibited.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "feedback",
    title: "Feedback",
    content: (
      <>
        <S.DescriptionText>
          You have no obligation to provide Modoyo with feedback or ideas.
          However, any feedback, suggestions, ideas, concepts or other
          information you provide to us or that you otherwise make available to
          us relating to the Services and associated media and materials or new
          potential games, modes, products, content, Services, functionalities
          or features (collectively, "<strong>Feedback</strong>") will be the
          sole property of Modoyo. T o this end, you irrevocably assign any and
          all rights, titles and interest you may have in the Feedback to
          Modoyo, including all intellectual property rights and the rights to
          alter, amend, translate or otherwise change the Feedback and the right
          to freely transfer, license and assign such Feedback. T o the extent
          any rights to the Feedback are not assignable, you grant Modoyo a
          perpetual, worldwide, irrevocable, royalty-free, sublicensable and
          transferable license to use, modify, reproduce, distribute, display,
          perform and create derivative works from the Feedback in any manner
          and for any purpose, including the right to freely change, amend or
          adapt the Feedback.
        </S.DescriptionText>
        <S.DescriptionText>
          Any Feedback you provide is deemed non-confidential and unsolicited.
          Modoyo is free to use Feedback without any obligation to you. Modoyo
          is under no obligation to review, use, implement or respond to any
          Feedback. Modoyo will not owe you any compensation, renumeration,
          consideration, credit or acknowledgment for the use or non-use of
          Feedback, and you hereby irrevocably waive any claims against Modoyo
          arising from Modoyo’s use or non-use of the Feedback.
        </S.DescriptionText>
        <S.DescriptionText>
          You acknowledge and agree that you retain no rights in the Feedback or
          in any Services including any games, modes, features, functionalities
          or other materials created or derived from the Feedback, whether such
          creations are identical or similar to, or entirely independent of,
          your Feedback.
        </S.DescriptionText>
        <S.DescriptionText>
          By providing us with Feedback you represent and warrant that you are
          the entitled to grant Modoyo the rights, titles and interest stated in
          this Section 10.
        </S.DescriptionText>
        <S.DescriptionText>
          You hereby acknowledge and expressly agree that your Feedback will not
          grant you any right, title or interest in the Services, in the
          Feedback itself or in any other game, product, service, material or
          work developed, produced or otherwise created by Modoyo.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "equipment-and-infrastructure",
    title: "Equipment and Infrastructure",
    content: (
      <>
        <S.DescriptionText>
          You are responsible for obtaining and maintaining an appropriate
          device, service plan, internet connection and all other required
          software, hardware or equipment to access and use the Services. We do
          not guarantee that the Services can be accessed and used on any
          specific hardware or device or with any specific service plan.
        </S.DescriptionText>
        <S.DescriptionText>
          If and to the extent the Services require access to third-party
          services or accounts, such as platform or digital storefront accounts,
          you are responsible for complying with the terms and conditions of
          those third parties.
        </S.DescriptionText>
        <S.DescriptionText>
          Modoyo is not liable for issues arising from third-party equipment,
          hardware, services or accounts such as platform accounts, internet
          access or terminal equipment (e.g., computers).
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "additional-software",
    title: "Additional Software",
    content: (
      <S.DescriptionText>
        The Services may contain additional software that requires you to agree
        to additional terms prior to your use thereof (“
        <strong>Additional Software</strong>”). You agree that Modoyo may
        install Additional Software on your hard drive as part of the
        installation of the Services (if any), from time to time during the term
        of this Agreement. You may not use any Additional Software in any way
        not explicitly authorized under the additional terms. In the event that
        Modoyo grants to you a valid license to use and activate the Additional
        Software, all use of the Additional Software shall be subject to the
        terms of this Agreement.
      </S.DescriptionText>
    ),
  },
  {
    id: "links",
    title: "Links to other websites or services",
    content: (
      <S.DescriptionText>
        The Services may contain links to other websites or services. Modoyo
        does not review or evaluate the websites or Services linked to, in or
        via the Services and is not responsible for the accuracy, copyright
        compliance, legality, decency or appropriateness of the content on such
        third party websites or services, nor is Modoyo responsible for the
        advertising, products or other materials that appear on or are offered
        by such third party websites or services. You should carefully review
        the respective conditions of use for any third party website or
        services. Under no circumstances shall Modoyo be held responsible or
        liable, directly or indirectly, for any loss or damages caused or
        alleged to have been caused by your use of or reliance on any content,
        goods, services available on such third party websites or services.
      </S.DescriptionText>
    ),
  },
  {
    id: "warranty-disclaimer",
    title: "Warranty Disclaimer",
    content: (
      <S.DescriptionText>
        TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, THE SERVICES ARE
        PROVIDED ON AN “AS IS” AND “AS AVAILABLE, ” BASIS FOR USE, WITHOUT
        WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT
        LIMITATION ANY IMPLIED WARRANTIES OF CONDITION, UNINTERRUPTED OR
        ERROR-FREE USE, MERCHANTABILITY , FITNESS FOR A PARTICULAR PURPOSE,
        NONINFRINGEMENT , TITLE AND THOSE ARISING FROM COURSE OF DEALING OR
        USAGE OF TRADE AND THE ENTIRE RISK ARISING OUT OF USE OR PERFORMANCE OF
        THE SERVICES AND ANY GAME(S) REMAINS WITH YOU, THE USER.
      </S.DescriptionText>
    ),
  },
  {
    id: "seizure-warning",
    title: "Seizure Warning",
    content: (
      <S.DescriptionText>
        Some users may experience seizures when exposed to certain light
        patterns or flashing lights. Exposure to certain light patterns or
        backgrounds on a video monitor may induce an epileptic seizure, even in
        persons who have no history of prior seizures or epilepsy. Modoyo takes
        no responsibility and shall not be liable for such effects and end
        results and you are using the Services at your own risk.
      </S.DescriptionText>
    ),
  },
  {
    id: "limitation-of-liability",
    title: "Limitation of Liability",
    content: (
      <S.DescriptionText>
        T o the fullest extent allowed by applicable law, Modoyo, its parent,
        subsidiaries, licensors, affiliates, agents, representatives and
        employees shall not be liable for any loss or damage arising out of your
        use of or inability to access or use, the Services. Without prejudice to
        the foregoing, Modoyo’s liability shall never exceed the total fees paid
        by you to Modoyo during the six (6) months prior to your making a claim
        against Modoyo, unless applicable law explicitly disallows this
        limitation, in which case Modoyo’s liability shall be limited to the
        fullest extent permitted by applicable law.
      </S.DescriptionText>
    ),
  },
  {
    id: "indemnity",
    title: "Indemnity",
    content: (
      <S.DescriptionText>
        You hereby agree to defend, hold harmless and indemnify Modoyo, its
        parent, subsidiaries, licensors and affiliates against and from any
        third party claims, liabilities, losses, injuries, damages, costs or
        expenses incurred by any of them arising out of or from any violation by
        you of this Agreement or your misuse of the Services or of any specific
        services or features associated therewith, including but not limited to
        any Feedback or Games.
      </S.DescriptionText>
    ),
  },
  {
    id: "term",
    title: "Term",
    content: (
      <>
        <S.DescriptionText>
          This Agreement becomes effective upon your acceptance of its terms and
          conditions, such as by clicking "accept" on a platform or within a
          game or by accessing and using the Services. The Agreement will remain
          in effect until terminated by either you or Modoyo in accordance with
          its terms.
        </S.DescriptionText>
        <S.DescriptionText>
          This Agreement shall automatically terminate, without further action
          by either party, if Modoyo determines, at its sole discretion, to
          discontinue any part of or all of the Services. Modoyo may provide
          reasonable advance notice of such discontinuation where practicable.
        </S.DescriptionText>
        <S.DescriptionText>
          We may terminate your access and use of the Services, a particular
          Service or any part thereof if we, in our sole discretion, determine
          that you have violated this Agreement or that there has otherwise been
          unlawful, improper or fraudulent use of the Services.
        </S.DescriptionText>
        <S.DescriptionText>
          If this Agreement is terminated, you will not have access to the
          Services (or the relevant parts thereof) and may you be barred from
          accessing or using the Services again. Upon termination, your
          license(s) under this Agreement also terminate. Consequently, you may
          lose access to the games and in-game purchases constituting part of
          our Services.
        </S.DescriptionText>
        <S.DescriptionText>
          Instead of terminating the Agreement, Modoyo may alternatively issue
          you a warning, suspend, restrict or alter your access to a particular
          Service or part thereof, remove or revoke access at account or device
          level, remove or delete any Content (as defined in the EULA) which is
          in violation with this Agreement. If Modoyo takes any action described
          in this Section, you will not be entitled to a refund (subject to any
          statutory refund rights). Actions are cumulative and repeated
          rule-breaking may result in this Agreement and any licenses hereunder,
          being terminated.
        </S.DescriptionText>
        <S.DescriptionText>
          When practical, Modoyo may notify you of any termination, suspension
          or other restriction of the Services, a particular Service or parts
          thereof.
        </S.DescriptionText>
        <S.DescriptionText>
          If you believe that any action has been taken against you in error,
          please contact our customer support at{" "}
          <a href="mailto:info@modoyo.com" target="_blank" rel="noreferrer">
            info@modoyo.com
          </a>
          .
        </S.DescriptionText>
        <S.DescriptionText>
          You may terminate this Agreement by sending us a written notification
          of your wish to terminate to the contact details set out below. If you
          terminate this Agreement, you agree and acknowledge, that all access
          to use of the Services, including our games and potential in-game
          purchases, shall cease and be terminated.
        </S.DescriptionText>
        <S.DescriptionText>
          Any provisions of this Agreement that, by their nature, are intended
          to survive termination or expiration shall remain in full force and
          effect. This includes, but is not limited to, provisions on: Modoyo’s
          ownership of its intellectual property rights, disclaimers of
          warranties, limitations of liability and governing law and dispute
          solutions.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "miscellaneous",
    title: "Miscellaneous",
    content: (
      <>
        <S.DescriptionText>
          Except as expressly set forth to the contrary herein, if any part of
          this Agreement is determined to be invalid, void, illegal or
          unenforceable, then that portion shall be severed, and the remainder
          of this Agreement shall be given full force and effect.
        </S.DescriptionText>
        <S.DescriptionText>
          Modoyo may assign this Agreement, in whole or in part, to any person
          or entity at any time with or without your consent. You may not assign
          this Agreement without Modoyo’s prior written consent. Your assignment
          of this Agreement without Modoyo’s prior written consent shall be
          void.
        </S.DescriptionText>
        <S.DescriptionText>
          Modoyo’s failure to enforce a provision of this Agreement shall not be
          construed as a (i) waiver of such provision, or (ii) diminishment of
          any right to enforce such provisions. Further, Modoyo may choose to
          waive enforcement of a provision of this Agreement in a particular
          instance; however, you are still obligated to comply with that waived
          provision in the future.
        </S.DescriptionText>
        <S.DescriptionText>
          This Agreement constitutes the entire agreement between the parties
          with respect to the subject matter hereof, and supersedes all previous
          written or oral agreements between the parties with respect to such
          subject matter.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "changes-to-the-agreement",
    title: "Changes to the Agreement",
    content: (
      <S.DescriptionText>
        Modoyo reserves the right to unilaterally modify, amend or add to or
        otherwise alter the terms of this Agreement or any part thereof at any
        time by reasonable notice via our website or via other appropriate
        means. Unless otherwise noted, the amended Agreement will be effective
        immediately and your continued use of the Services will confirm your
        acceptance of the changes. If you do not agree to the amended Agreement,
        you must stop using the Services.
      </S.DescriptionText>
    ),
  },
  {
    id: "class-action-waiver",
    title: "Class Action Waiver",
    content: (
      <S.DescriptionText>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT ANY
        CLAIMS UNDER THIS AGREEMENT MUST BE MADE IN YOUR INDIVIDUAL CAPACITY ,
        AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
        REPRESENTATIVE PROCEEDING. YOU AGREE THAT CLASS ACTION LAWSUITS,
        CLASS-WIDE ARBITRATIONS, PRIVATE ATTORNEY-GENERAL ACTIONS, REQUESTS FOR
        PUBLIC INJUNCTIONS, AND ANY OTHER PROCEEDING OR REQUEST FOR RELIEF WHERE
        SOMEONE ACTS IN A REPRESENTATIVE CAPACITY ARE NOT ALLOWED AND WAIVE ANY
        RIGHT YOU MAY HAVE TO ANY SUCH PROCEDURE, INCLUDING COMBINING INDIVIDUAL
        PROCEEDINGS WITHOUT THE CONSENT OF ALL PARTIES.
      </S.DescriptionText>
    ),
  },
  {
    id: "disputes-governing-law",
    title: "Disputes & Governing Law",
    content: (
      <>
        <S.DescriptionText>
          PLEASE READ THIS SECTION CAREFULLY . IT MAY SIGNIFICANTLY AFFECT YOUR
          RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT OR TO PURSUE
          CLAIMS IN A CLASS OR REPRESENTATIVE CAPACITY .
        </S.DescriptionText>
        <S.DescriptionText>
          This Agreement shall be governed by and construed in accordance with
          the substantive law of Sweden, without regard to its conflicts of laws
          principles. The application of the United Nations Convention on
          Contracts for the International Sale of Goods is expressly excluded.
        </S.DescriptionText>
        <S.DescriptionText>
          To the fullest extent under applicable law, any dispute, controversy
          or claim arising out of or in connection with the Agreement, or the
          breach, termination or invalidity thereof, shall be finally settled by
          arbitration administered by the SCC Arbitration Institute (the “
          <strong>SCC</strong>”). The Rules for Expedited Arbitrations shall
          apply, unless the SCC in its discretion determines, considering the
          complexity of the case, the amount in dispute and other circumstances,
          that the Arbitration Rules shall apply. In the latter case, the SCC
          shall also decide whether the Arbitral Tribunal shall be composed of
          one or three arbitrators. The seat of arbitration shall be Stockholm,
          Sweden. The language to be used in the arbitral proceedings shall be
          English, unless otherwise agreed by the Parties.
        </S.DescriptionText>
        <S.DescriptionText>
          If you are a consumer residing within the EU, you may be entitled to
          bring a claim to the court in your country of residence.
        </S.DescriptionText>
        <S.DescriptionText>
          With respect to any claims not subject to binding individual
          arbitration and which cannot be brought in small claims court in the
          country in which you reside as set out above, the Parties agree to
          submit to the exclusive jurisdiction of the courts of Sweden, with the
          district court of Stockholm (Sw. Stockholms Tingsrätt) as the first
          instance.
        </S.DescriptionText>
        <S.DescriptionText>
          Notwithstanding this Section 22 Modoyo shall be entitled to take
          direct legal action in a competent court within your home jurisdiction
          as necessary to reasonably secure Modoyo’s rights and interests and to
          be able to enforce any judgment obtained by Modoyo. SECTIONS 21 AND 22
          SHALL APPLY TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW.
        </S.DescriptionText>
      </>
    ),
  },
  {
    id: "contact-information",
    title: "Contact Information",
    content: (
      <>
        <S.DescriptionText>
          Modoyo is a Swedish limited liability company, corporate reg. no.
          559252-7625 having its registered offices at Tjärhovsgatan 34, 116 21
          Stockholm, Sweden.
        </S.DescriptionText>
        <S.DescriptionText>
          Email:{" "}
          <a href="mailto:info@modoyo.com" target="_blank" rel="noreferrer">
            info@modoyo.com
          </a>
        </S.DescriptionText>
        <S.DescriptionText>
          Phone number: <a href="tel:+46 (0)793247214">+46 (0)793247214</a>
        </S.DescriptionText>
        <S.DescriptionText>
          Further information and contact options may from be available time to
          time at our website:
        </S.DescriptionText>
        <S.DescriptionText>
          <a href="https://modoyo.com" target="_blank" rel="noreferrer">
            www.modoyo.com
          </a>
        </S.DescriptionText>
      </>
    ),
  },
];
