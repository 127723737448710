import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-inline: 2.5rem;

  @media (max-width: 900px) {
    padding-top: 40px;
  }

  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props["2xl"] &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;

export const Title = styled.div`
  font-size: 56px;
  line-height: 70px;
  font-weight: 700;
  font-family: Figtree, sans-serif;
  color: #1c122e;
  margin-top: 30px;

  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 50px;
    margin-top: 0;
    margin-bottom: 40px;
    text-align: left;
  }
`;

export const LastUpdatedText = styled.p`
  font-size: 18px;
  line-height: 27px;
  font-weight: ${({ $isBold }) => ($isBold ? 700 : 400)};
  color: #1c122e;
  font-style: ${({ $isBold }) => ($isBold ? "normal" : "italic")};
  font-family: "Roboto Flex", sans-serif;
  margin: 0;
  padding-bottom: 5px;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
`;

export const ContentSectionContainer = styled.div`
  position: relative;
  width: calc(100% - 300px);
  margin-top: 100px;
  padding-bottom: 80px;

  @media (max-width: 900px) {
    display: none;
`;

export const NavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  padding: 0 !important;
  padding-left: 15px;
  position: sticky;
  top: 140px;
  width: 250px;
`;

export const NavigationItem = styled.li`
  display: flex;
  gap: 8px;
  align-items: baseline;
  margin: 0;
  padding: 0;
`;

export const NavigationTitle = styled.a`
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 500;
  font-family: "Roboto Mono", sans-serif;
  color: #b2249b;
`;

export const BulletPoint = styled.span`
  color: #b2249b;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-left: 300px;
  margin-top: -355px;
`;

export const DescriptionItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DescriptionTitle = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  font-family: Figtree, sans-serif;
  color: #1c122e;
  scroll-margin-top: 140px;
  margin-bottom: 24px;

  @media (max-width: 900px) {
    font-family: "Roboto Flex", sans-serif;
  }
`;

export const DescriptionText = styled.p`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #000000;
  white-space: pre-line;
  margin: 0 0 24px 0;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
`;

export const DescriptionSubTitle = styled.div`
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  font-family: Figtree, sans-serif;
  color: #000000;
  margin-bottom: 24px;
`;

export const DefinitionsList = styled.ul`
  list-style-type: disc;
  margin-top: 0;
`;

export const DefinitionsSubList = styled.ul`
  list-style-type: lower-alpha;
  margin-top: 0;
`;

export const DefinitionsListItem = styled.li`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #000000;
  white-space: pre-line;
  text-decoration: dotted;
  margin-block: 15px;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
