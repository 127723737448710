import withTracker from "../../hoc/withTracker";
import * as S from "./style";
import * as GS from "../../styles/global-style";
import ContentSection from "../../components/EULA/ContentSection";

function EULA() {
  return (
    <GS.HeaderFooterSpace $disabledFooterSpace>
      <S.EULAContainer>
        <ContentSection />
      </S.EULAContainer>
    </GS.HeaderFooterSpace>
  );
}

export default withTracker(EULA);
