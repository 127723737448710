import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-inline: 2.5rem;

  @media (max-width: 900px) {
    padding-top: 40px;
  }

  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props["2xl"] &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;

export const Title = styled.div`
  font-size: 56px;
  line-height: 70px;
  font-weight: 700;
  font-family: Figtree, sans-serif;
  color: #1c122e;
  margin-top: 30px;

  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 50px;
    margin-top: 0;
    margin-bottom: 40px;
    text-align: left;
  }
`;

export const LasUpdatedText = styled.p`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #1c122e;
  font-family: "Roboto Flex", sans-serif;
  font-style: italic;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
`;

export const ContentSectionContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 100px;
  padding-bottom: 80px;
  gap: 40px;

  @media (max-width: 900px) {
    display: none;
`;

export const NavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  padding: 0 !important;
  padding-left: 15px;
  position: sticky;
  top: 140px;
  width: 350px;
  overflow-y: auto;
  height: calc(100vh - 160px);
  scrollbar-width: thin;
  scrollbar-color: #d3d3d3 transparent;
  scroll-behavior: smooth;
`;

export const NavigationItem = styled.li`
  display: flex;
  gap: 8px;
  align-items: baseline;
  margin: 0;
  padding: 0;
  ${({ $isSubCategory }) => ($isSubCategory ? "margin-left: 17px" : "0")}
`;

export const NavigationTitle = styled.a`
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 500;
  font-family: "Roboto Mono", sans-serif;
  color: #b2249b;
  width: ${({ index }) => (index === 2 || index === 9 ? "85%" : "100%")};
`;

export const BulletPoint = styled.span`
  color: #b2249b;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  @media (max-width: 900px) {
    gap: 16px;
  }
`;

export const DescriptionItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: ${({ $isSubCategory }) => ($isSubCategory ? "-40px" : "0")};
`;

export const DescriptionTitle = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  font-family: Figtree, sans-serif;
  color: #5b28b5;
  scroll-margin-top: 140px;
  visibility: ${({ $isSubCategory }) =>
    $isSubCategory ? "hidden" : "visible"};
  height: ${({ $isSubCategory }) => ($isSubCategory ? "0" : "auto")};
  margin-bottom: ${({ $isSubCategory }) => ($isSubCategory ? "0" : "24px")};
`;

export const DescriptionText = styled.p`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #000000;
  white-space: pre-line;
  margin: 0 0 24px 0;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
`;

export const DescriptionSubTitle = styled.div`
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  font-family: "Figtree", sans-serif;
  color: #000000;
  text-align: center;
  margin-bottom: 30px;
  margin-top: ${({ $isGrid }) => ($isGrid ? "30px" : "6px")};
`;

export const DefinitionsList = styled.ul`
  list-style-type: disc;
  margin-top: 0;
`;

export const DefinitionsListItem = styled.li`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #000000;
  white-space: pre-line;
  text-decoration: dotted;
  margin-block: 15px;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
`;

export const ChevronContainer = styled.svg`
  width: 20px;
  height: 20px;
  cursor: pointer;
  align-self: flex-start;

  @media (max-width: 900px) {
    width: 24px;
    height: 24px;
  }
`;
