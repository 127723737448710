import * as S from "./style";
import {contentData} from "./ContentData";
import MobileContainer from "./MobileContainer";

export default function ContentSection() {
  return (
    <S.Container $xl>
      <S.Title>End User License Agreement</S.Title>
      <S.LastUpdatedText>Last updated: 14th January 2025</S.LastUpdatedText>
      <S.ContentSectionContainer>
        <S.NavigationList>
          {contentData.map((item) => (
            <S.NavigationItem key={item.id}>
              <S.BulletPoint>•</S.BulletPoint>
              <S.NavigationTitle
                href={`#${item.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  const target = document.getElementById(item.id);
                  if (target) {
                    target.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                }}
              >
                {item.title}
              </S.NavigationTitle>
            </S.NavigationItem>
          ))}
        </S.NavigationList>
        <S.DescriptionContainer>
          <S.LastUpdatedText $isBold>
            Kindly note that you must carefully read through this end user license agreement before accessing or playing
            our games.
          </S.LastUpdatedText>
          <S.LastUpdatedText $isBold>
            By accepting this end user license agreement and/or by accessing or otherwise using any of Modoyo’s games or
            related services, you acknowledge that you have read and agreed to be bound by the terms and conditions
            hereof.
          </S.LastUpdatedText>
          <S.LastUpdatedText $isBold>
            If you do not agree with this end user license agreement, you may not access or use any of Modoyo’s games or
            related services.
          </S.LastUpdatedText>
          {contentData.map((item) => (
            <S.DescriptionItem key={item.id}>
              <S.DescriptionTitle id={item.id}>{item.title}</S.DescriptionTitle>
              {item.content}
            </S.DescriptionItem>
          ))}
        </S.DescriptionContainer>
      </S.ContentSectionContainer>
      <MobileContainer/>
    </S.Container>
  );
}
